import { API_GATEWAY } from "configs/AppConfig"

export const getPromoCodesSeriesRequest = async (token, data) => {
  console.log('data', data)
  Object.entries(data).forEach(([key, value]) => {
    if (!value) {
      delete data[key]
    }
  })
  const query = new URLSearchParams(data).toString()
  return await fetch(`${API_GATEWAY}/promo-codes?${query}`, {
    method: "GET",
    mode: 'cors',
    headers: {
      accept: '*/*',
      'Content-Type': 'application/json',
      "Api-Token": token,
    },
  }).then(res => {
    console.log('getPromoCodesSeriesRequest result', res.status);
    if (res.status === 200) return res.json();
    return null;
  });
}
export const getPromoCodesPrefilledSerieRequest = async (token) => {
  return await fetch(`${API_GATEWAY}/promo-codes/prefilled-serie`, {
    method: "GET",
    mode: 'cors',
    headers: {
      accept: '*/*',
      'Content-Type': 'application/json',
      "Api-Token": token,
    },
  }).then(res => {
    console.log('getPromoCodesPrefilledSerieRequest result', res.status);
    if (res.status === 200) return res.json();
    return null;
  });
}

export const savePromoCodeSerieRequest = async (token, values) => {
  console.log('savePromoCodeSerieRequest', values)
  let query = `${API_GATEWAY}/promo-codes`;
  let method = "POST"

  return await fetch(query, {
    method,
    mode: 'cors',
    headers: {
      accept: '*/*',
      'Content-Type': 'application/json',
      "Api-Token": token,
    },
    body: JSON.stringify(values)
  }).then(res => {
    return res.json();
  }).finally(() => {
    return null
  });
}

export const getPromoSerieCodesRequest = async (token, id) => {
  return await fetch(`${API_GATEWAY}/promo-codes/${id}/generated-codes`, {
    method: "GET",
    mode: 'cors',
    headers: {
      accept: '*/*',
      'Content-Type': 'application/json',
      "Api-Token": token,
    },
  }).then(res => {
    console.log('getPromoSerieCodesRequest', res.status);
    if (res.status === 200) return res.json();
    return null;
  });
}

export const saveCustomizationRequest = async (token, values, id) => {
  console.log('saveCustomizationRequest', values)
  let query = `${API_GATEWAY}/promo-codes/${id}/theme`;
  let method = "PATCH"

  return await fetch(query, {
    method,
    mode: 'cors',
    headers: {
      accept: '*/*',
      'Content-Type': 'application/json',
      "Api-Token": token,
    },
    body: JSON.stringify(values)
  }).then(res => {
    console.log('saveCustomizationRequest result', res.status);
    if (res.status === 200) return res.json();
    return null;
  });
}

export const getCustomizationRequest = async (token, id) => {
  return await fetch(`${API_GATEWAY}/promo-codes/${id}/theme`, {
    method: "GET",
    mode: 'cors',
    headers: {
      accept: '*/*',
      'Content-Type': 'application/json',
      "Api-Token": token,
    },
  }).then(res => {
    console.log('getCustomizationRequest', res.status);
    if (res.status === 200) return res.json();
    return null;
  });
}

export const uploadCustomizationImageRequest = async (token, id, data, imageType) => {

  const formData = new FormData();
  formData.append('image', data);

  return await fetch(`${API_GATEWAY}/promo-codes/${id}/${imageType}`, {
    method: "POST",
    mode: 'cors',
    headers: {
      accept: '*/*',
      // 'Content-Type': 'multipart/form-data; boundary=CUSTOM',
      "Api-Token": token,
    },
    body: formData
  }).then(res => {
    console.log('uploadCustomizationImageRequest result', res.status);
    if (res.status === 200) return res.json();
    return null;
  });
}

export const getPromoActivatedListRequest = async (token, id, where) => {
  return await fetch(`${API_GATEWAY}/promo-codes/${id}/activated?where=${JSON.stringify(where)}`, {
    method: "GET",
    mode: 'cors',
    headers: {
      accept: '*/*',
      'Content-Type': 'application/json',
      "Api-Token": token,
    },
  }).then(res => {
    console.log('getPromoActivatedListRequest result', res.status);
    if (res.status === 200) return res.json();
    return null;
  });
}

export const managePromoSerieRequest = async (token, id, command) => {
  return await fetch(`${API_GATEWAY}/promo-codes/${id}/manage/${command}`, {
    method: "GET",
    mode: 'cors',
    headers: {
      accept: '*/*',
      'Content-Type': 'application/json',
      "Api-Token": token,
    },
  }).then(res => {
    console.log('managePromoSerieRequest', res.status);
    if (res.status === 200) return res.json();
    return null;
  });
}

export const blockPromoCodeRequest = async (token, serie, value) => {
  return await fetch(`${API_GATEWAY}/promo-codes/${serie}/block/${value}`, {
    method: "GET",
    mode: 'cors',
    headers: {
      accept: '*/*',
      'Content-Type': 'application/json',
      "Api-Token": token,
    },
  }).then(res => {
    console.log('blockPromoCodeRequest', res.status);
    if (res.status === 200) return res.json();
    return null;
  });
}

export const getPromoSerieByIdRequest = async (token, id) => {
  return await fetch(`${API_GATEWAY}/promo-codes/${id}`, {
    method: "GET",
    mode: 'cors',
    headers: {
      accept: '*/*',
      'Content-Type': 'application/json',
      "Api-Token": token,
    },
  }).then(res => {
    console.log('getPromoSerieByIdRequest result', res.status);
    if (res.status === 200) return res.json();
    return null;
  });
}